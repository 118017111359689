export default function(state, action) {
    switch (action.type) {
/*     case 'RECEIVED_PROFILE':
        return {
            ...state,
            values: {
                ...action.data,
            },
        }; */
    default:
        return state;
    }
}
