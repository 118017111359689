export default {
  primary: '#409ef8',
  primaryDark: '#274bc8',
  danger: '#ff564e',
  success: '#28bc00',
  warn: '#ffc000',
  black: '#000',
  dark: '#262626',
  light: '#fff',
  fullDark: '#000',
  blueDark: '#343b4c',
  blueLight: '#d9f3ff',
  arrowBlueDark: '#6674a6',
  snackbarBlueDark: '#3d4454',
  purpleDeep: '#3e217d',
  controls: {
    grey: {
      default: '#ccc', // рамки кнопок
      disabled: '#dde0e8', // задизейбленные контролы
      icon: 'rgba(94, 94, 94, 0.4)', // иконки
      background: '#f5f7f8', // бэкграунд кнопок
      lightBackground: '#eef2f4', // бэкграунд иконки на нотификациях
      activeBackground: 'rgba(141, 150, 178, 0.2)', // бэкграунд при :active состоянии на checkbox
      outline: '#b0b4c2', // обводка на outline контролах
      fieldOutline: '#dde0e8', // обводка на outline контролах
      iconBackground: '#f3f4f7',
      placeholder: '#a4a9b8',
      fieldIcon: '#B0B4C2',
      disabledText: '#afb5c9'
    }
  }
}
