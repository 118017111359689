import {ActionTypes} from 'utils/constants';

export default function(state, action) {
    switch (action.type) {
    case ActionTypes.SET_REF_PRODUCTS:
        return {
            ...state,
            values: {
                ...state.values,
                ref_product: [...action.data],
            },
        };
    default:
        return state;
    }
}
