export default {
  months: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ],
  days: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],
  pagination: {
    label: 'На страницу',
    tooltip: 'Такая страница отсутствует'
  }
}
